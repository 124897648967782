/*
 *  AEConnect.portal - a Web Application for Archimede Energia's Battery
 *
 *  Copyright (C) 2023   Vincenzo Barbato (vincenzo.barbato.51999@gmail.com)
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, either version 3 of the License, or
 *  (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 *
 * This code is made available on the understanding that it will not be
 * used in safety-critical situations without a full and competent review.
 */

/* You can add global styles to this file, and also import other style files */
* {
  box-sizing: border-box;
}
body,
html {
  padding: 0px;
  margin: 0px;
  background: #343334;
}

@font-face {
  font-family: "MuseoSans-300";
  src: url("../src/style/font-family/daaf7e1dffe4ec230866bb0ad8c7c245.eot");
  src: url("../src/style/font-family/daaf7e1dffe4ec230866bb0ad8c7c245.eot?#iefix")
      format("embedded-opentype"),
    url("../src/style/font-family/daaf7e1dffe4ec230866bb0ad8c7c245.woff2")
      format("woff2"),
    url("../src/style/font-family/daaf7e1dffe4ec230866bb0ad8c7c245.woff")
      format("woff"),
    url("../src/style/font-family/daaf7e1dffe4ec230866bb0ad8c7c245.ttf")
      format("truetype"),
    url("../src/style/font-family/daaf7e1dffe4ec230866bb0ad8c7c245.svg#Museo Sans 300 Regular")
      format("svg");
}

.plus:after {
  content: "\02795"; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: white;
  float: right;
  margin-left: 5px;
}

.plus:after {
  content: "\2796"; /* Unicode character for "minus" sign (-) */
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid white;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px rgb(65, 65, 65) inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: white;
}

/* scroll style */

*::-webkit-scrollbar {
  position: absolute;
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: rgb(54, 54, 54);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(117, 117, 117);
  border-radius: 0px;
  border: 1px solid rgb(54, 54, 54);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgb(137, 137, 137);
}

*::-webkit-scrollbar-thumb:active {
  background-color: rgb(164, 164, 164);
}

*::-webkit-scrollbar-button:single-button {
  background-color: rgb(54, 54, 54);
  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}

/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 12px;
  width: 16px;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(137, 137, 137)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(164, 164, 164)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 12px;
  width: 16px;
  background-position: center 2px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(137, 137, 137)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(164, 164, 164)'><polygon points='0,0 100,0 50,50'/></svg>");
}

/* scroll style */

button:active {
  transform: translateY(+1px);
}

.icon {
  position: relative;
  width: 15px;
  margin: 5px 7px 5px 7px;
  padding: 0px 0px 0px 0px;
}

.icon_close {
  position: absolute;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  top: 50%;
  margin-top: -7px;
  left: 50%;
  margin-left: -7px;
  width: 14px;
  height: 14px;
}

.circle {
  border: 2px solid white;
  background-color: rgba(255, 86, 86, 0.868);
  color: white;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 6px;
  width: 20px;
  height: 20px;
  padding: 0px 0px 0px 0px;
}
